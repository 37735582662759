import React from 'react';
import styles from './OrderHistory.module.scss'
import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { IMsalContext } from '@azure/msal-react/dist/MsalContext';
import APIService, { IAPIServiceResponse } from './services/APIService';
import { IOrder } from './interfaces';
import OrderHistoryCard from './OrderHistoryCard';



export type TSubmitStatus = "started" | "completed" | "completedWithErrors" | "pending";

export interface IOrderHistoryState {
    pastOrders:IOrder[];
    dataLoadCompleted:boolean;
}

export interface IOrderHistoryProps {
    msalContext:IMsalContext;
    companyId:string;
    reOrder(order:IOrder):void;
}


export default class OrderHistory extends React.Component<IOrderHistoryProps, IOrderHistoryState> {

    public constructor(props: IOrderHistoryProps) {
        super(props);

        
        
        this.state = {
            pastOrders:[],
            dataLoadCompleted:false,
        };


    }

    public async componentDidMount() {
        this._loadPastOrders();
    }

    componentDidUpdate(prevProps: Readonly<IOrderHistoryProps>, prevState: Readonly<IOrderHistoryState>): void {
        if(prevProps.companyId !== this.props.companyId){
            this.setState({dataLoadCompleted:false});
            this._loadPastOrders();
        }
    }

    public render(): React.ReactElement<IOrderHistoryProps> {

     
        return (
            <div>
            <Stack className={styles.OrderHistory} horizontalAlign='center' tokens={{childrenGap:15}} verticalAlign='space-between'>
                <Stack horizontalAlign='center' tokens={{childrenGap:15}}>
                    
                    <div className={styles.header2}>Order History</div>
                    {this._getHistoryControls()}
                    
                </Stack>
                
              
            </Stack>

           
            </div>

        );
    }

    private async _loadPastOrders():Promise<void> {
        
        const pastOrdersResponse:IAPIServiceResponse = await APIService.getPastOrders(this.props.msalContext,this.props.companyId);
        let pastOrders:IOrder[] = [];
        if(pastOrdersResponse.result === 'succeeded'){
            pastOrders = pastOrdersResponse.data;
        }
        this.setState({pastOrders:pastOrders,dataLoadCompleted:true});
    }

    private _getHistoryControls():any {
        if(!this.state.dataLoadCompleted){
            return <Spinner size={SpinnerSize.medium} />;
        }

        return (
            <Stack tokens={{childrenGap:15}}>
                {this.state.pastOrders.map(o=>{
                    return <OrderHistoryCard key={`pastOrder_${o.id}`} pastOrder={o} reOrder={this.props.reOrder}/>
                }
                )}
            </Stack>
        );
    }

}
