import { IMsalContext } from "@azure/msal-react";
import { IOrder } from "../interfaces";

export interface IAPIServiceResponse {
    result: "succeeded" | "failed" | undefined;
    data:any;
    error?:any;
    stateName:string;
  }

  //test default
  //let apiBaseUrl:string = "https://localhost:7263"
//   let apiBaseUrl:string = "https://truckorderscustomerapi-test.azurewebsites.net"
//   let defaultScope:string = "https://ecob2c.onmicrosoft.com/700dbb61-23b0-4fae-9a43-9d8850122033/Customer.Default"

  const apiBaseUrl:string = String(process.env.REACT_APP_APIBASEURL);
  const defaultScope:string = String(process.env.REACT_APP_APISCOPE);
  
// let apiBaseUrl:string = "https://truckorderscustomerapi-prod.azurewebsites.net"
// let defaultScope:string = "https://ecob2c.onmicrosoft.com/700dbb61-23b0-4fae-9a43-9d8850122033/Customer.Default"


export default class APIService {

    private static async _getHttpClientOptions (msalContext:IMsalContext,method:"GET" | "POST" | "PATCH" = "GET",postBody?:string): Promise<any> {
        let accessTokenResponse:any = undefined;
        accessTokenResponse = await msalContext.instance.acquireTokenSilent({scopes:[defaultScope],account:msalContext.accounts[0]});
        // try{
        //     accessTokenResponse = await msalContext.instance.acquireTokenSilent({scopes:[defaultScope],account:msalContext.accounts[0]});
        // }
        // catch{
        //     await msalContext.instance.loginRedirect();
        //     accessTokenResponse = await msalContext.instance.acquireTokenSilent({scopes:[defaultScope],account:msalContext.accounts[0]});
        // }
        const accessToken:string = accessTokenResponse.accessToken;
        const headers:Headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);
        headers.append("Accept", "application/json");
        //headers.append('Access-Control-Allow-Origin', '*');
        
        const options:any = {
            method: method,
            headers: headers
        };

        if(method === 'POST' && postBody){
            headers.append("Content-Type", "application/json");
            options.method = "POST";
            options.body = postBody;
        }
        if(method === 'PATCH' && postBody){
            headers.append("Content-Type", "application/json");
            options.method = "PATCH"
            options.body = postBody;
        }

        return options;
    }

  
    public static async getAllSources (msalContext:IMsalContext): Promise <IAPIServiceResponse> {

        const response: IAPIServiceResponse = {
            result: undefined,
            data: undefined,
            error: undefined,
            stateName: "sources",
        };

        if(!apiBaseUrl){
            response.result='failed';
            return response;
        }

        const apiEndpoint:string = `${apiBaseUrl}/Sources`;

        const options = await this._getHttpClientOptions(msalContext,'GET');

        try {

            const fetchResponse:Response = await fetch(apiEndpoint,options);
            response.data = JSON.parse(await fetchResponse.json());
            response.result = 'succeeded';

        } catch (error) {
            console.error(`Exception occured while processing sources request`, error);
            response.result='failed';
            response.error=(error);
        }
    
        return response;
    }

    public static async getAllProducts (msalContext:IMsalContext): Promise <IAPIServiceResponse> {

        const response: IAPIServiceResponse = {
            result: undefined,
            data: undefined,
            error: undefined,
            stateName: "products",
        };

        if(!apiBaseUrl){
            response.result='failed';
            return response;
        }

        const apiEndpoint:string = `${apiBaseUrl}/Products`;

        const options = await this._getHttpClientOptions(msalContext,'GET');

        try {

            const fetchResponse:Response = await fetch(apiEndpoint,options);
            const document:any = JSON.parse(await fetchResponse.json())[0];
            response.data = [];
            document.products.forEach((p:any)=>response.data.push(p));
            response.result = 'succeeded';

        } catch (error) {
            console.error(`Exception occured while processing sources request`, error);
            response.result='failed';
            response.error=(error);
        }
    
        return response;
    }

    public static async getMyCompanies (msalContext:IMsalContext): Promise <IAPIServiceResponse> {
        
        const response: IAPIServiceResponse = {
            result: undefined,
            data: undefined,
            error: undefined,
            stateName: "companies",
        };

        if(!apiBaseUrl){
            response.result='failed';
            return response;
        }

        const apiEndpoint:string = `${apiBaseUrl}/MyCompanies`;

        const options = await this._getHttpClientOptions(msalContext,'GET');

        try {

            const fetchResponse:Response = await fetch(apiEndpoint,options);
            response.data = JSON.parse(await fetchResponse.json());
            response.result = 'succeeded';

        } catch (error) {
            console.error(`Exception occured while processing companies request`, error);
            response.result='failed';
            response.error=(error);
        }
    
        return response;
    }

    public static async getPastOrders (msalContext:IMsalContext,companyId:string,limit?:number): Promise <IAPIServiceResponse> {
        
        const response: IAPIServiceResponse = {
            result: undefined,
            data: undefined,
            error: undefined,
            stateName: "pastOrders",
        };

        if(!apiBaseUrl){
            response.result='failed';
            return response;
        }

        let apiEndpoint:string = `${apiBaseUrl}/Orders/${companyId}`;
        if(limit){
            apiEndpoint += `?limit=${limit}`
        }

        const options = await this._getHttpClientOptions(msalContext,'GET');

        try {

            const fetchResponse:Response = await fetch(apiEndpoint,options);
            response.data = JSON.parse(await fetchResponse.json());
            response.result = 'succeeded';

        } catch (error) {
            console.error(`Exception occured while processing past orders request`, error);
            response.result='failed';
            response.error=(error);
        }
    
        return response;
    }

    public static async postOrder (msalContext:IMsalContext,order:IOrder): Promise <IAPIServiceResponse> {

        const response: IAPIServiceResponse = {
            result: undefined,
            data: undefined,
            error: undefined,
            stateName: "orderResults",
        };

        if(!apiBaseUrl){
            response.result='failed';
            return response;
        }

        const apiEndpoint:string = `${apiBaseUrl}/Orders`;

        if(order.jobOrPurchaseOrder === 'testbadid'){
            order.id='testbadid';
        }

        const options = await this._getHttpClientOptions(msalContext,'POST',JSON.stringify(order));

        try {

            const fetchResponse:Response = await fetch(apiEndpoint,options);
            console.log('postOrder_response',fetchResponse);
            if(fetchResponse && fetchResponse.status === 201){
                response.result = 'succeeded';
            }
            else{
                throw new Error("postOrder Failed");
            }
            

        } catch (error) {
            console.error(`Exception occured while processing orders request for orderid: ${order.id}`, error);
            response.result='failed';
            response.error=(error);
        }
    
        return response;
    }
  

}