import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, WithMsalProps } from "@azure/msal-react";
import styles from './CustomerTruckOrdersApp.module.scss'
import { ComboBox, IComboBox, IComboBoxOption, Label, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import emlogo01 from './ecomaterial-technologies-logo-cw.svg';
import { sortBy, uniqBy } from 'lodash';
import { IAddress, ICompany, IProduct } from './interfaces';
import OrderForm from './OrderForm';
import { Lang } from './types';
import APIService, { IAPIServiceResponse } from './services/APIService';
import { InteractionStatus } from '@azure/msal-browser';


export interface ICustomerTruckOrdersAppState {
    selectedCompanyKey: string;
    selectedCompany?:ICompany;
    sources:IAddress[];
    products:IProduct[];
    companies:ICompany[];
    companyOptions:IComboBoxOption[];
    stateOptions:IComboBoxOption[];
    lang:Lang;
    initialdataloadCompleted:boolean;
    didLoginOnce:boolean;
}

export interface ICustomerTruckOrdersAppProps {

}


export default class CustomerTruckOrdersApp extends React.Component<(ICustomerTruckOrdersAppProps & WithMsalProps), ICustomerTruckOrdersAppState> {

    public constructor(props: ICustomerTruckOrdersAppProps & WithMsalProps) {
        super(props);

        this._onCompanyChange = this._onCompanyChange.bind(this);
        this._getCompanyAreaControls = this._getCompanyAreaControls.bind(this);
        

        this.state = {
            selectedCompanyKey: '',
            selectedCompany:undefined,
            sources:[],
            products:[],
            companies:[],
            companyOptions:[],
            stateOptions:[],
            lang:'en',
            initialdataloadCompleted:false,
            didLoginOnce:false,
        };


    }

    public async componentDidMount(): Promise<void> {

    }

    private callLogin() {
        console.log('this.props.msalContext',this.props.msalContext);
        let isAuthenticated:boolean = false;
        if(this.props.msalContext.accounts && this.props.msalContext.accounts.length > 0){
            isAuthenticated = true;
            if(this.props.msalContext.accounts[0].idTokenClaims && this.props.msalContext.accounts[0].idTokenClaims.exp){
                
                if(this.props.msalContext.accounts[0].idTokenClaims.exp > new Date().getTime()){
                    isAuthenticated = true;
                    console.log('exp',isAuthenticated);
                }
            }
        }
        const msalInstance = this.props.msalContext.instance;

        // If a user is not logged in and authentication is not already in progress, invoke login
        if (!isAuthenticated && this.props.msalContext.inProgress === InteractionStatus.None) {
            msalInstance.loginRedirect();
        }
    }

    public async componentDidUpdate() {
        console.log('componentDidUpdate_CustomerTruckOrdersApp');
        if(!this.state.didLoginOnce){
            if(this.props.msalContext && this.props.msalContext.accounts && this.props.msalContext.accounts.length > 0){
                this.setState({didLoginOnce:true});
                await this._loadAllData();
            }
        }
        if(this.state.didLoginOnce){
            this.callLogin();
        }
        
    }
    

    public render(): React.ReactElement<ICustomerTruckOrdersAppProps & WithMsalProps> {
        
        
        return (

            <Stack className={styles.CustomerTruckOrdersApp}>
                <Stack className={styles.header} horizontal horizontalAlign='space-between' verticalAlign='center'>
                    <div className={styles.loginButtonWidth}/>
                    <img style={{width:200}} src={emlogo01} alt='Eco Logo' />
                    <Stack>
                    <AuthenticatedTemplate>
                        <PrimaryButton className={`${styles.loginButtonWidth} ${styles.ecoButton}`} onClick={() => this.props.msalContext.instance.logout()}>Logout</PrimaryButton>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <PrimaryButton className={`${styles.loginButtonWidth} ${styles.ecoButton}`} onClick={() => this.props.msalContext.instance.loginRedirect()}>Login</PrimaryButton>
                    </ UnauthenticatedTemplate>
                </Stack>
                </Stack>
                <Stack className={styles.contentWrapper} tokens={{childrenGap:15}}>
                    <Stack className={styles.companySection} horizontalAlign='center'>
                        <AuthenticatedTemplate>
                            {this._getCompanyAreaControls()}
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <div className={styles.header1}>Please login to place order(s)</div>
                        </UnauthenticatedTemplate>
                    </Stack> 
                    <AuthenticatedTemplate>
                        {this.state.selectedCompanyKey && this.state.selectedCompany &&
                        <Stack horizontalAlign="center">
                            <Stack horizontalAlign='stretch' style={{maxWidth:900,width:'100%'}} grow>
                                <OrderForm 
                                    selectedCompany={this.state.selectedCompany} 
                                    lang={this.state.lang} 
                                    sources={this.state.sources}
                                    stateOptions={this.state.stateOptions}
                                    msalContext={this.props.msalContext}
                                    products={this.state.products}
                                />
                            </Stack>
                        </Stack>
                        }
                    </AuthenticatedTemplate>          
                </Stack>
            </Stack>

        );
    }

    private _getCompanyAreaControls():any {
        if(!this.state.initialdataloadCompleted){
            return <Spinner size={SpinnerSize.medium} />;
        }
        if(!this.state.companies || (this.state.companies && this.state.companies.length === 0)){
            return <Label>You have not been assigned to a company.  Please contact your account representative.</Label>;
        }
        return (
            <Stack>
                {this.state.selectedCompanyKey && this.state.selectedCompany &&
                    <div className={styles.header1}>{this.state.selectedCompany.name}</div>
                }
                <ComboBox
                    selectedKey={this.state.selectedCompanyKey}
                    label="My Company Profiles"
                    autoComplete="on"
                    options={this.state.companyOptions}
                    onChange={this._onCompanyChange}
                />
            </Stack>
        );
    }

    private _onCompanyChange(event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined){
        
        let company:ICompany | undefined = undefined;
        let selectedCompanyKey:string  = '';
        if(option && option.key){
            selectedCompanyKey = String(option.key);
            const filteredCompanyResults = this.state.companies.filter(c => {
                return c.id === option.key;
            })
            if(filteredCompanyResults && filteredCompanyResults.length > 0){
                company = filteredCompanyResults[0]
            }
        }

        this.setState({selectedCompany:company,selectedCompanyKey:selectedCompanyKey})   
    }

    private async _loadAllData():Promise<void> {
        const objState:any={};

        const apiResults: IAPIServiceResponse[] = await Promise.all([
          APIService.getAllSources(this.props.msalContext),
          APIService.getMyCompanies(this.props.msalContext),
          APIService.getAllProducts(this.props.msalContext),
        ]);

        const companyOptions:IComboBoxOption[] = [];
        let stateOptions:IComboBoxOption[] = [];
        let selectedCompanyKey:string = '';
        let selectedCompany:any = undefined;

        apiResults.map(r=>{
          if(r.result === "succeeded"){
            objState[r.stateName] = r.data;
            if(r.stateName === "companies"){
                if(r.data){
                    r.data.map((c:ICompany)=>{
                        companyOptions.push({key:c.id,text:`${c.name} (${c.id})`})
                    })
                    if(companyOptions.length > 0){
                        selectedCompanyKey = String(companyOptions[0].key);
                        selectedCompany = r.data[0];
                    }
                    
                }
              }
              if(r.stateName === "sources"){
                if(r.data){
                    const uniqState:any = uniqBy(r.data,"state")
                    uniqState.map((a:IAddress)=>{
                        if(a.state.length === 2){
                            stateOptions.push({key:a.state,text:a.state})
                        }
                    });
                    stateOptions = sortBy(stateOptions,"text");
                }
              }
          }
          
        })
    
        this.setState({
            initialdataloadCompleted:true,
            companyOptions:companyOptions,
            stateOptions:stateOptions,
            selectedCompanyKey:selectedCompanyKey,
            selectedCompany:selectedCompany,
            ...objState
        });

      }
    
}