import React from "react";
import { MsalProvider, withMsal } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import * as authConfig from './authConfig'
import CustomerTruckOrdersApp from './CustomerTruckOrdersApp';

const pca = new PublicClientApplication(authConfig.msalConfig);

const WrappedCustomerTruckOrdersApp = withMsal(CustomerTruckOrdersApp);

export default class AppWrapper extends React.Component {
    render() {
        return (
            <MsalProvider instance={pca}>
                <WrappedCustomerTruckOrdersApp />
            </ MsalProvider>
        );
    }
}