import { Stack } from '@fluentui/react';
import * as React from 'react';
import styles from './AddressCard.module.scss';
import EMLogo from './EM_GreenWhite.png';
import { IAddress } from './interfaces';

export interface IAddressCardProps {
    address?:IAddress;
    selected?:boolean;
    onSelectAddressForLineItemId?(address:IAddress):void;
    cardType:'smallDisplay' | 'select' | 'blank';
    showEMLogo?:boolean;
}


export default class AddressCard extends React.Component<IAddressCardProps, {}> {

    public render(): React.ReactElement<IAddressCardProps> {
        if(this.props.cardType === 'blank' || !this.props.address){
        
            return(
                <Stack className={`${styles.AddressCard} ${styles.blankCard}`} 
                    verticalAlign='center'
                    horizontalAlign='center'
                    tokens={{childrenGap:10}} 
                    // onClick={()=>this._handleClick()}
                    style={this.props.cardType === 'blank' ? {cursor:'pointer'} : undefined}
                ><div>Address</div>
                </Stack>
            )
        }
        return (
            <Stack
                className={`${styles.AddressCard} ${styles.card1} ${this.props.cardType === 'select' ? styles.withHover : undefined}`} 
                verticalAlign='space-between' 
                tokens={{childrenGap:10}}
                onClick={()=>this._handleClick()}
                style={this.props.cardType === 'select' ? {cursor:'pointer'} : undefined}
            >
                <h2 className={styles.title}>{this.props.address.name}</h2>
                <Stack horizontal horizontalAlign='space-between' verticalAlign='end'>
                <Stack>
                    <div>{this.props.address.street1}</div>
                    {this.props.address && this.props.address.street2 &&
                        <div>{this.props.address.street2 }</div>
                    }
                    {this.props.address && this.props.address.street3 &&
                        <div>{this.props.address.street3 }</div>
                    }
                    <div>{`${this.props.address.city}, ${this.props.address.state} ${this.props.address.zip}`}</div>
                </Stack>
                {this.props.showEMLogo &&
                    <img alt="emLogo" src={EMLogo} width={36}/>
                }
                </Stack>           
            </Stack>

        );
    }

    private _handleClick(){
        if(this.props.address && this.props.cardType === 'select' && this.props.onSelectAddressForLineItemId){
            this.props.onSelectAddressForLineItemId(this.props.address)
        }
    }

}
