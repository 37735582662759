import React from 'react';
import styles from './LineItemCard.module.scss'
import { Dropdown, IDropdownOption, Label, Link, Stack, SpinButton, DatePicker, IconButton, TextField } from '@fluentui/react';
import { IAddress, IOrderLineItem, IProduct  } from './interfaces';
import AddressCard from './AddressCard';
import { includes } from 'lodash';

export interface ILineItemCardState {
    tonsOrLoadsKey?:string;
    deleteConfirm:boolean;
}

export interface ILineItemCardProps {
    states:IDropdownOption[];
    lineItem:IOrderLineItem;
    myShipTos:IAddress[];
    reviewMode:boolean;
    invalidFields:string[];
    onOpenSourcePanelForLineItemId(LineItemId:string):void;
    onOpenShipToPanelForLineItemId(LineItemId:string):void;
    onSelectStateforAllPanelItemId(LineItemId:string,state:IDropdownOption|undefined):void;
    onDeleteLineItem(LineItemId:string):void;
    onUpdateLineItemFieldValue(lineItemId:string,field:string,value:any):void;
    products:IProduct[];
}


export default class LineItemCard extends React.Component<ILineItemCardProps, ILineItemCardState> {

    public constructor(props: ILineItemCardProps) {
        super(props);

        this._getAlertClass = this._getAlertClass.bind(this);
        this._getProductOptions = this._getProductOptions.bind(this);
        
        this.state = {
            tonsOrLoadsKey:'loads',
            deleteConfirm:false,
        };


    }
    public render(): React.ReactElement<ILineItemCardProps> {
        console.log('product',this.props.lineItem.product);
    
        return (
            <Stack className={styles.LineItemCard} tokens={{childrenGap:15}}>
                <Stack horizontal tokens={{childrenGap:15}} horizontalAlign='space-between' wrap> 
                    <Stack horizontal tokens={{childrenGap:15}} wrap verticalAlign='start'>
                        <Dropdown 
                            label='State' 
                            options={this.props.states} 
                            style={{width:100}} 
                            selectedKey={this.props.lineItem.sourceState ? this.props.lineItem.sourceState.key : undefined} 
                            onChange={(e,o)=>this.props.onSelectStateforAllPanelItemId(this.props.lineItem.id,o ? o : undefined)}
                            disabled={this.props.reviewMode}
                        />
                        <Stack horizontal tokens={{childrenGap:15}} wrap>
                            <Stack>
                                <Stack horizontal tokens={{childrenGap:10}} >
                                    <Label>Source</Label>
                                    <Link onClick={()=>this.props.onOpenSourcePanelForLineItemId(this.props.lineItem.id)} 
                                        disabled={!this.props.reviewMode && this.props.lineItem && this.props.lineItem.sourceState ? false : true}
                                    >
                                        {this.props.lineItem && this.props.lineItem.source ? 'Change' : 'Choose'}
                                    </Link>
                                </Stack>
                                
                                {this.props.lineItem && this.props.lineItem.source
                                    ?<AddressCard showEMLogo address={this.props.lineItem.source} cardType='smallDisplay' />
                                    :<AddressCard cardType='blank' />
                                }
                            </Stack>

                            <Stack>
                                <Stack horizontal tokens={{childrenGap:10}}>
                                    <Label>Ship To</Label>
                                    <Link onClick={()=>this.props.onOpenShipToPanelForLineItemId(this.props.lineItem.id)} 
                                        disabled={!this.props.reviewMode && this.props.lineItem && this.props.lineItem.sourceState ? false : true}
                                    >
                                        {this.props.lineItem && this.props.lineItem.shipTo ? 'Change' : 'Choose'}
                                    </Link>
                                </Stack>
                            
                                {this.props.lineItem && this.props.lineItem.shipTo 
                                    ?<AddressCard address={this.props.lineItem.shipTo} cardType='smallDisplay' />
                                    :<AddressCard cardType='blank' />
                                }
                            </Stack>
                        </Stack>
                   </Stack>
                   
                    
                         
                   
                </Stack>

                
                <Stack horizontal tokens={{childrenGap:15}} verticalAlign='center' wrap>
                    <Dropdown 
                        label='Product' 
                        options={this._getProductOptions()}
                        selectedKey={this.props.lineItem && this.props.lineItem.product ? this.props.lineItem.product.key : null}
                        style={{width:300}}
                        disabled={!this.props.reviewMode && this.props.lineItem && this.props.lineItem.sourceState ? false : true}
                        onChange={(e,o)=>this.props.onUpdateLineItemFieldValue(this.props.lineItem.id,'product',o)}
                    />
                    {/* <ChoiceGroup
                        options={[
                            {key:'tons',text:"Tons"},
                            {key:'loads',text:"Loads"},
                        ]}
                        selectedKey={this.state.tonsOrLoadsKey}
                        onChange={(e,o)=>this.setState({tonsOrLoadsKey:o ? String(o.key) : 'loads',quantity:0})}
                        disabled={!this.props.reviewMode && this.props.lineItem && this.props.lineItem.sourceState ? false : true}
                    /> */}
                    {/* <Stack.Item align='end'>
                        <Label>Loads</Label>
                    </Stack.Item> */}
                    <Stack>
                        <Label className={this._getAlertClass('loadsQuantity')}>Loads</Label>
                        <SpinButton
                            // label='Loads'
                            min={0}
                            max={1000}
                            precision={0}
                            step={this.state.tonsOrLoadsKey && this.state.tonsOrLoadsKey === 'tons' ? 25 : 1}
                            styles={{root:{width:150}}}
                            value={this.props.lineItem.loadsQuantity ? String(this.props.lineItem.loadsQuantity) : String(0)}
                            onChange={(e,v)=>this.props.onUpdateLineItemFieldValue(this.props.lineItem.id,'loadsQuantity',v ? Number(Math.round(Number(v)).toFixed(0)) : 0)}
                            disabled={!this.props.reviewMode && this.props.lineItem && this.props.lineItem.sourceState ? false : true}
                            
                            
                        />
                    </Stack>
                    
                    
                </Stack>
                <Stack horizontal tokens={{childrenGap:15}} wrap horizontalAlign='space-between'>
                    <Stack horizontal tokens={{childrenGap:15}} wrap={window.innerWidth <= 400 ? true : false} verticalAlign='center'>
                        <DatePicker
                            styles={window.innerWidth <= 400 ? {root:{width:185,marginTop:5,marginLeft:5}}:{root:{width:185,marginTop:5}}}
                            label='Requested Pickup Date'
                            disabled={!this.props.reviewMode && this.props.lineItem && this.props.lineItem.sourceState ? false : true}
                            value={this.props.lineItem && this.props.lineItem.requestedPickupDate ? new Date(this.props.lineItem.requestedPickupDate) : undefined}
                            onSelectDate={d=>this.props.onUpdateLineItemFieldValue(this.props.lineItem.id,'requestedPickupDate',d ? d.toISOString() : null)}
                            minDate={new Date(new Date().setHours(0))}
                        />
                        <TextField 
                            label='Job Number or Purchase Order' 
                            disabled={!this.props.reviewMode && this.props.lineItem && this.props.lineItem.sourceState ? false : true}
                            onChange={(e,v)=>this.props.onUpdateLineItemFieldValue(this.props.lineItem.id,'jobOrPurchaseOrder',v)}
                            value={this.props.lineItem && this.props.lineItem.jobOrPurchaseOrder ? this.props.lineItem.jobOrPurchaseOrder : ''}
                            styles={window.innerWidth <= 400 ? {root:{marginLeft:5}} : {root:{marginLeft:15}}}
                        />
                    </Stack>
                    {/* <Dropdown 
                        label='Load Type' 
                        options={[
                            {key:'CPU',text:'CPU'},
                        ]}
                        style={{width:150}}
                        defaultSelectedKey={'CPU'}
                        disabled={!this.props.reviewMode && this.props.lineItem && this.props.lineItem.sourceState ? false : true}
                    /> */}
                    
                    <Stack.Item align='end'>
                    {this.props.lineItem.submitStatus &&
                        <Stack>{this._getOrderSubmitStatus(this.props.lineItem.submitStatus)}</Stack>
                    }
                    {this.state.deleteConfirm
                    ?
                    <Stack horizontal>
                        <Label>Confirm Delete?</Label>
                        <IconButton iconProps={ {iconName: 'CheckMark' }} onClick={()=>this.props.onDeleteLineItem(this.props.lineItem.id)} />
                        <IconButton iconProps={ {iconName: 'Cancel' }} onClick={()=>this.setState({deleteConfirm:false})} />
                        
                        </Stack>  
                    :
                        <IconButton 
                            iconProps={ {iconName: 'Delete' }} 
                            onClick={()=>this.setState({deleteConfirm:true})} 
                            disabled={!this.props.reviewMode && this.props.lineItem && this.props.lineItem.sourceState ? false : true}
                        />
                    }
                    </Stack.Item>
                    
                    
                </Stack>
                
            </Stack>




        );
    }

    // private _getFillAlert(field:string):any {
    //     const styles:IIconStyles = {
    //         root:{
    //             color:ecoTheme.redAlert,
    //         }
    //     }
    //     return <Icon styles={styles} iconName='AlertSolid' />
    // }

    private _getProductOptions():IDropdownOption[]{
        let options:IDropdownOption[] = [];
        options.push({key:'unknown',text:'Unknown'});
        if(this.props.lineItem.source && 'isTerminal' in this.props.lineItem.source && String(this.props.lineItem.source.isTerminal).toLowerCase() === 'true'){
            options=[];
            if(this.props.lineItem.source.subSources && this.props.lineItem.source.subSources.length > 0 && this.props.lineItem.source.subSourcesToShow && this.props.lineItem.source.subSourcesToShow.length > 0){
                options.push({key:'unknown',text:'Unknown'});
                this.props.lineItem.source.subSources.forEach(ss=>{
                    if(includes(this.props.lineItem.source ? this.props.lineItem.source.subSourcesToShow : [],ss.GP_SiteID)){
                        options.push({key:ss.GP_SiteID,text:ss.Description});
                    }
                })
            }

        }
        else{

            if(this.props.lineItem && this.props.lineItem.source && this.props.lineItem.source.productsToShow && this.props.lineItem.source.productsToShow.length > 0){
                this.props.products.forEach(p=>{
                    if(includes(this.props.lineItem.source && this.props.lineItem.source.productsToShow ? this.props.lineItem.source.productsToShow : [],p.value))
                    options.push({key:p.value,text:p.label});
                });
            }
            
        }
        return options;
    }

    private _getOrderSubmitStatus(submitStatus:string):any{
        if(submitStatus === 'succeeded'){
            return <Label style={{color:'#00b803'}}>Submitted Successfully</Label>;
        }
        return(
            <Label style={{color:'#c90e0e'}}>Failed - Please contact account rep</Label>
        )
    }

    private _getAlertClass(field:string):any {
        // console.log(field);
        // console.log(this.props.invalidFields);
        // console.log(this.props.invalidFields.includes(field));
        if(this.props.invalidFields.includes(field)){
            return styles.missingValue;
        }
        return undefined;
    }

}
