import React from 'react';
import styles from './OrderForm.module.scss'
import { DefaultButton, IComboBoxOption, IDropdownOption, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Stack } from '@fluentui/react';
import { IAddress, ICompany, IOrder, IOrderLineItem, IProduct, ISource } from './interfaces';
import { Lang } from './types';
import LineItemCard from './LineItemCard';
import AddressCard from './AddressCard';
import APIService from './services/APIService';
import { IMsalContext } from '@azure/msal-react';
import OrderHistory from './OrderHistory';
import AddNewShipToForm from './AddNewShipToForm';


export type TSubmitStatus = "started" | "completed" | "completedWithErrors" | "pending";

export interface IOrderFormState {
    LineItems:IOrderLineItem[];
    shipToPanelIsOpen:boolean;
    allPanelLineItemId?:string;
    sourcePanelIsOpen:boolean;
    allPanelSelectedStateKey?:string;
    reviewMode:boolean;
    submitStatus:TSubmitStatus;
    allOrdersValid:boolean;
    selectedPivotKey:string;
    showAddShipToForm:boolean;
}



export interface IOrderFormProps {
    selectedCompany:ICompany;
    lang: Lang;
    sources:IAddress[];
    stateOptions:IComboBoxOption[];
    msalContext:IMsalContext;
    products:IProduct[];
}


export default class OrderForm extends React.Component<IOrderFormProps, IOrderFormState> {

    private defaultProductOption:IDropdownOption;

    public constructor(props: IOrderFormProps) {
        super(props);

        this._addLineItem = this._addLineItem.bind(this);
        this._openShipToPanelForLineItemId = this._openShipToPanelForLineItemId.bind(this);
        this._openSourcePanelForLineItemId = this._openSourcePanelForLineItemId.bind(this);
        this._selectShipToForLineItemId = this._selectShipToForLineItemId.bind(this);
        this._selectSourceForLineItemId = this._selectSourceForLineItemId.bind(this);
        this._selectStateforAllPanelItemId = this._selectStateforAllPanelItemId.bind(this);
        this._deleteLineItem = this._deleteLineItem.bind(this);
        this._updateLineItemFieldValue = this._updateLineItemFieldValue.bind(this);
        this._submitOrders = this._submitOrders.bind(this);
        this._clearOrder = this._clearOrder.bind(this);
        this._reviewOrders = this._reviewOrders.bind(this);
        this._onPivotClick = this._onPivotClick.bind(this);
        this._reOrder = this._reOrder.bind(this);
        this._toggleShowAddShipTo = this._toggleShowAddShipTo.bind(this);
        
        this.defaultProductOption = {key:'Unknown',text:'Unknown'};
        
        this.state = {
            LineItems: [],
            shipToPanelIsOpen:false,
            allPanelLineItemId:undefined,
            sourcePanelIsOpen:false,
            allPanelSelectedStateKey:undefined,
            reviewMode:false,
            submitStatus:'pending',
            allOrdersValid:false,
            selectedPivotKey:'newOrders',
            showAddShipToForm:false,
        };


    }

    public componentDidUpdate(prevProps:IOrderFormProps): void {
        if(!this.props.selectedCompany){
            this.setState({LineItems:[]});
        }
        if(this.props.selectedCompany && this.props.selectedCompany.id){
            if(this.props.selectedCompany.id !== prevProps.selectedCompany.id){
                this.setState({LineItems:[]});
            }
        }
    }

    public render(): React.ReactElement<IOrderFormProps> {

     
        return (
            <Pivot
                styles={{
                    linkIsSelected:{
                        color:'#323130',
                        backgroundColor:'#ffffff'
                    },
                    link:{
                        color:'#323130',
                        backgroundColor:'#ffffff'
                    }
                }}
                selectedKey={this.state.selectedPivotKey}
                onLinkClick={this._onPivotClick}
                >
                <PivotItem headerText="New Orders" itemKey='newOrders'>
                <Stack className={styles.OrderForm} horizontalAlign='center' tokens={{childrenGap:15}} verticalAlign='space-between'>
                <Stack horizontalAlign='center' tokens={{childrenGap:15}}>
                    
                    <div className={styles.header2}>New Truck Order(s)</div>
                    {this.state.submitStatus !== 'pending'
                        ?(this.state.reviewMode &&
                            <PrimaryButton 
                                className={styles.ecoButton} 
                                text='Start New Order' 
                                style={{width:200}} 
                                onClick={this._clearOrder}
                            />
                        )
                        :<PrimaryButton 
                        className={styles.ecoButton} 
                        text='Add Customer Pickup' 
                        style={{width:200}} 
                        onClick={this._addLineItem}
                        disabled={this.state.reviewMode}
                    />
                    }
                    
                    <Stack className={styles.lineItemArea} tokens={{childrenGap:25}}>
                        {this.state.LineItems && this.state.LineItems.length > 0 &&
                            this.state.LineItems.map(li=>
                            <LineItemCard 
                                states={this.props.stateOptions} 
                                lineItem={li} 
                                myShipTos={this.props.selectedCompany.shipToAddresses} 
                                onOpenShipToPanelForLineItemId={this._openShipToPanelForLineItemId} 
                                onOpenSourcePanelForLineItemId={this._openSourcePanelForLineItemId} 
                                key={`li_${li.id}`}
                                onSelectStateforAllPanelItemId={this._selectStateforAllPanelItemId}
                                onDeleteLineItem={this._deleteLineItem}
                                onUpdateLineItemFieldValue={this._updateLineItemFieldValue}
                                reviewMode={this.state.reviewMode}
                                invalidFields={this._validateLineItem(li)}
                                products={this.props.products}
                            />)
                        }
                    </Stack>
                </Stack>
                
                {this.state.reviewMode && this.state.submitStatus === 'pending'
                ?<Stack horizontal tokens={{childrenGap:15}}>
                    <DefaultButton 
                        //disabled={this.state.LineItems && this.state.LineItems.length > 0 ? false : true} 
                        className={styles.ecoDefaultButton} 
                        text='Change' 
                        style={{width:200,marginTop:40}}
                        onClick={()=>this.setState({reviewMode:false})}
                    />
                    <PrimaryButton 
                        disabled={!this.state.allOrdersValid} 
                        className={styles.ecoButton} 
                        text='Submit' 
                        style={{width:200,marginTop:40}}
                        onClick={()=>this._submitOrders()}
                    />
                </Stack>
                :(this.state.submitStatus === 'pending' &&
                    <PrimaryButton 
                    disabled={this.state.LineItems && this.state.LineItems.length > 0 ? false : true} 
                    className={styles.ecoButton} 
                    text='Review' 
                    style={{width:200,marginTop:40}}
                    onClick={()=>this._reviewOrders()}
                />
                )
                
                }
            </Stack>

            <Panel
                headerText="Choose Ship To"
                isOpen={this.state.shipToPanelIsOpen}
                onDismiss={()=>this.setState({shipToPanelIsOpen:false})}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close"
                type={PanelType.medium}
            >
                <Stack tokens={{childrenGap:20}}>
                    {this.state.showAddShipToForm
                    ? <AddNewShipToForm toggleShowAddShipTo={this._toggleShowAddShipTo} />
                    : 
                    <Stack.Item align='center'>
                        {/* <PrimaryButton styles={{root:{width:300}}} className={styles.ecoButton} text="Submit New Ship To Request" onClick={()=>this._toggleShowAddShipTo()} /> */}
                    </Stack.Item>
                    }
                    
                    {!this.state.showAddShipToForm && 
                        <Stack tokens={{childrenGap:10}}>
                            {this.props.selectedCompany.shipToAddresses && 
                                this.props.selectedCompany.shipToAddresses.map((st:IAddress)=>{
                                    return <AddressCard address={st} onSelectAddressForLineItemId={this._selectShipToForLineItemId} cardType='select' key={`st_${st.id}`} />
                                })
                            }
                        </Stack>
                    }
                    
                </Stack>
                
            </Panel>

            <Panel
                headerText="Choose Source"
                isOpen={this.state.sourcePanelIsOpen}
                onDismiss={()=>this.setState({sourcePanelIsOpen:false})}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close"
                type={PanelType.medium}
            >
                <Stack tokens={{childrenGap:10}}>
                    {this.props && 
                        this.props.sources.map((sc:IAddress)=>{
                            if(sc.state === this.state.allPanelSelectedStateKey){
                                return <AddressCard showEMLogo address={sc} onSelectAddressForLineItemId={this._selectSourceForLineItemId} cardType='select' key={`sc_${sc.id}`} />
                            }
                        })
                    }
                </Stack>
            </Panel>
                </PivotItem>
                <PivotItem headerText="Order History" itemKey="pastOrders">
                    <OrderHistory
                    msalContext={this.props.msalContext}
                    companyId={this.props.selectedCompany && this.props.selectedCompany.id ? this.props.selectedCompany.id : ''}
                    reOrder={this._reOrder}
                    />
                </PivotItem>

            </Pivot>


        );
    }

    private _toggleShowAddShipTo() {
        this.setState({showAddShipToForm:!this.state.showAddShipToForm});
    }

    private _addLineItem() {

        let sourceState:any = undefined;
        
        if(this.props.selectedCompany && this.props.selectedCompany.shipToAddresses){
            const st = this.props.selectedCompany.shipToAddresses[0].state;
            sourceState = {text:st,key:st};
        }
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        

        const newLineItem:IOrderLineItem = {
            id:crypto.randomUUID(),
            sourceState:sourceState ? sourceState : undefined,
            source:undefined,
            shipTo:undefined,
            loadsQuantity:0,
            product:this.defaultProductOption,
            loadType:{key:'CPU',text:'CPU'},
            requestedPickupDate:tomorrow.toLocaleDateString(),
            jobOrPurchaseOrder:'',
        }
        const lineItems:IOrderLineItem[] = this.state.LineItems.slice();
        lineItems.push(newLineItem);
        this.setState({LineItems:lineItems});

    }

    private _deleteLineItem(lineItemId:string) {
        const lineItems:IOrderLineItem[] = this.state.LineItems.slice();
        let newLineItems:IOrderLineItem[] = lineItems.filter(li=>{return li.id !== lineItemId});
        this.setState({LineItems:newLineItems});
    }

    private _getLineItemSelectedStateKey(lineItemId:string):string | undefined{
        let selectedStateKey:string | undefined | number = undefined;
        this.state.LineItems.forEach(li=>{
            if(li.id === lineItemId && li.sourceState){
                selectedStateKey = li.sourceState.key
            }
        })
        return selectedStateKey;
    }

    private _openShipToPanelForLineItemId(lineItemId:string){
        this.setState({allPanelLineItemId:lineItemId,shipToPanelIsOpen:true,allPanelSelectedStateKey:this._getLineItemSelectedStateKey(lineItemId)})
    }

    private _openSourcePanelForLineItemId(lineItemId:string){
        this.setState({allPanelLineItemId:lineItemId,sourcePanelIsOpen:true,allPanelSelectedStateKey:this._getLineItemSelectedStateKey(lineItemId)})
    }

    private _selectShipToForLineItemId(shipTo:IAddress){
        const lineItems:IOrderLineItem[] = this.state.LineItems.slice();
        lineItems.forEach(li=>{
            if(this.state.allPanelLineItemId && li.id === this.state.allPanelLineItemId){
                li.shipTo = shipTo;
            }
        })
        this.setState({LineItems:lineItems,shipToPanelIsOpen:false,allPanelLineItemId:undefined});
    }

    private _selectSourceForLineItemId(source:ISource){
        const lineItems:IOrderLineItem[] = this.state.LineItems.slice();
        lineItems.forEach(li=>{
            if(this.state.allPanelLineItemId && li.id === this.state.allPanelLineItemId){
                li.source = source;
                li.product = undefined;
                // if(source && 'isTerminal' in source && String(source.isTerminal).toLowerCase() === 'true'){
                //     li.product = undefined;
                // }
                // else{
                //     li.product = this.defaultProductOption;
                // }
            }
        })
        this.setState({LineItems:lineItems,sourcePanelIsOpen:false,allPanelLineItemId:undefined});
    }

    private _updateLineItemFieldValue(lineItemId:string,field:string,value:any){
        const lineItems:IOrderLineItem[] = this.state.LineItems.slice();
        lineItems.forEach(li=>{
            if(lineItemId && li.id === lineItemId){
                switch (field) {
                    case 'loadsQuantity':
                        li.loadsQuantity = value;
                        break;
                    
                    case 'product':
                        li.product = value;
                        break;

                    case 'requestedPickupDate':
                        li.requestedPickupDate = value;
                        break;
                    case 'jobOrPurchaseOrder':
                        li.jobOrPurchaseOrder = value;
                        break;
                        
                    default:
                        break;
                }

            }
        })
        this.setState({LineItems:lineItems});
    }

    private _selectStateforAllPanelItemId(lineItemId:string,state:IDropdownOption | undefined){
        const lineItems:IOrderLineItem[] = this.state.LineItems.slice();
        lineItems.forEach(li=>{
            if(li.id === lineItemId){
                li.sourceState = state;
            }
        })
        this.setState({LineItems:lineItems});
    }

    private _validateLineItem(lineItem:IOrderLineItem):string[]{
        const invalidFields:string[] = [];
        if(!lineItem.loadsQuantity || String(lineItem.loadsQuantity) === "0"){
            invalidFields.push('loadsQuantity');
        }
        if(!lineItem.source){
            invalidFields.push('source');
        }
        if(!lineItem.shipTo){
            invalidFields.push('shipTo');
        }
        if(!lineItem.product){
            invalidFields.push('product');
        }
        return invalidFields;
    }

    private _reviewOrders():void{
        this.setState({reviewMode:true});
        let allValid:boolean = true;
        this.state.LineItems.map(li=>{
            const validationResult = this._validateLineItem(li);
            if(validationResult.length > 0){
                allValid = false;
            }
        })

        this.setState({allOrdersValid:allValid});
    }

    private async _submitOrders():Promise<void>{

        this.setState({submitStatus:'started'});
        //validation
        let allValid:boolean = true;
        this.state.LineItems.map(li=>{
            const validationResult = this._validateLineItem(li);
            if(validationResult.length > 0){
                allValid = false;
            }
        })
       

        const entryId:string = crypto.randomUUID();

        let submitStatus:TSubmitStatus = 'completed'

        if(allValid){
            for(const li of this.state.LineItems){
                try{
                    const order:IOrder = {
                        id:li.id,
                        entryId:entryId,
                        companyId:this.props.selectedCompany.id,
                        companyName:this.props.selectedCompany.name,
                        sourceState:li.sourceState,
                        source:li.source,
                        shipTo:li.shipTo,
                        product:li.product,
                        loadsQuantity:li.loadsQuantity,
                        requestedPickupDate:li.requestedPickupDate,
                        jobOrPurchaseOrder:li.jobOrPurchaseOrder,
                        loadType:li.loadType,
                        
                    }
                    const orderPostResult = await APIService.postOrder(this.props.msalContext,order);
                    if(orderPostResult && orderPostResult.result === 'succeeded'){
                        li.submitStatus = 'succeeded';
                    }
                    else {
                        throw new Error("Error submitting order");
                    }
                }
                catch{
                    li.submitStatus = 'failed';
                    submitStatus = 'completedWithErrors'
                }
            }
            
        }

        this.setState({reviewMode:true,submitStatus:submitStatus});

    }

    private _clearOrder():void{
        this.setState({reviewMode:false,submitStatus:'pending',LineItems:[]});
    }


    private _reOrder(order:IOrder):void{

        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        
        let sourceMatch:ISource | undefined = undefined;
        if(order && order.source){
            const sourceMatches:ISource[] = this.props.sources.filter(s=>s.id === order.source?.id);
            if(sourceMatches && sourceMatches.length > 0){
                sourceMatch = sourceMatches[0];
            }
        }


        let productMatch:IDropdownOption | undefined = undefined;
        if(sourceMatch && 'isTerminal' in sourceMatch && String(sourceMatch.isTerminal).toLowerCase() === "true"){
            if(order && order.product && sourceMatch.subSources && sourceMatch.subSources.length > 0){
                const convertedSourceOptions:IDropdownOption[] = [];
                sourceMatch.subSources.forEach(ss=>convertedSourceOptions.push({key:ss.GP_SiteID,text:ss.Description}));
                const productMatches:IDropdownOption[] = convertedSourceOptions.filter(ss=>ss.key === order.product?.key);
                if(productMatches && productMatches.length > 0){
                    productMatch = productMatches[0];
                }
            }
        }
        else{
            if(order && order.product){
                const convertedProductOptions:IDropdownOption[] = [];
                this.props.products.forEach(p=>convertedProductOptions.push({key:p.value,text:p.label}));
                const productMatches:IDropdownOption[] = convertedProductOptions.filter(p=>p.key === order.product?.key);
                if(productMatches && productMatches.length > 0){
                    productMatch = productMatches[0];
                }
            }
        }

       
        console.log('productMatch',productMatch);

        const newLineItem:IOrderLineItem = {
            id:crypto.randomUUID(),
            sourceState:order.sourceState,
            source:sourceMatch,
            shipTo:order.shipTo,
            loadsQuantity:0,
            product:productMatch,
            loadType:order.loadType,
            requestedPickupDate:tomorrow.toLocaleDateString(),
            jobOrPurchaseOrder:'',
        }
        let lineItems:IOrderLineItem[] = this.state.LineItems.slice();
        lineItems = lineItems.filter(l=>l.submitStatus === undefined)
        lineItems.push(newLineItem);
        this.setState({LineItems:lineItems,selectedPivotKey:'newOrders',reviewMode:false,submitStatus:'pending'});
    }

    private _onPivotClick (item?: any | undefined, ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined){
        console.log('_onPivotClick',item);
        if(item){
            this.setState({selectedPivotKey:String(item.props.itemKey)});
        }
    }

}
