import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppWrapper from './AppWrapper';
import { initializeIcons } from '@fluentui/react/lib/Icons';

initializeIcons();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
        <AppWrapper />
  </React.StrictMode>
);
// root.render(
//   <AppWrapper />
// );

