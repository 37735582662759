import React from 'react';
import styles from './OrderForm.module.scss'
import { Dropdown, IDropdownOption, Label, Link, Stack, SpinButton, DatePicker, IconButton, TextField, PrimaryButton, DefaultButton } from '@fluentui/react';
import { IAddress, IOrderLineItem, ISelectOption  } from './interfaces';
import AddressCard from './AddressCard';
import statesOptions from './StatesOptions.json';
import Select from 'react-select';


export interface IAddNewShipToFormState {
    name:string;
    street1:string;
    street2:string;
    city:string;
    addressState:string;
    zip:string;
}

export interface IAddNewShipToFormProps {
    toggleShowAddShipTo():void;
}


export default class AddNewShipToForm extends React.Component<IAddNewShipToFormProps, IAddNewShipToFormState> {

    public constructor(props: IAddNewShipToFormProps) {
        super(props);

        this.state = {
            name:'',
            street1:'',
            street2:'',
            city:'',
            addressState:'',
            zip:'',
        };


    }
    public render(): React.ReactElement<IAddNewShipToFormProps> {
      
        return (
            <Stack styles={{root:{minHeight:600}}}>
                <TextField label='Name' onChange={(e,n)=>this.setState({name:n ? n : ''}) } />
                <TextField label='Street1' onChange={(e,n)=>this.setState({street1:n ? n : ''}) }  />
                <TextField label='Street2' onChange={(e,n)=>this.setState({street2:n ? n : ''}) }  />
                <TextField label='City' onChange={(e,n)=>this.setState({city:n ? n : ''}) }  />
                <Stack>
                    <Label>State</Label>
                    <Select
                        options={statesOptions}
                        value={this._getAddressStateOption(this.state.addressState)}
                        onChange={(n)=>this.setState({addressState:n && n.value ? n.value : ''})}
                        styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              width:200,
                            }),
                            menu: (baseStyles, state) => ({
                              ...baseStyles,
                              width:200,
                              zIndex:2,
                            }),
                          }}  
                    /> 
                </Stack>
                
                <TextField styles={{root:{width:100}}} label='Zip' onChange={(e,n)=>this.setState({zip:n ? n : ''}) } />
                <Stack horizontal style={{marginTop:20}} tokens={{childrenGap:15}}>
                    <PrimaryButton className={styles.ecoButton} text='Submit' />
                    <DefaultButton className={styles.ecoDefaultButton} text='Cancel' onClick={()=>this._cancelInput()} />
                </Stack>
            </Stack>

        );
    }

    private _getAddressStateOption(value:string){
        if(value){
            const chosenStateFiltered:ISelectOption[] = statesOptions.filter(s=>s.value === value);
            if(chosenStateFiltered && chosenStateFiltered.length > 0){
                return chosenStateFiltered[0]
            }
        }
        return undefined;
    }

    private _cancelInput(){
        this.setState({
            name:'',
            street1:'',
            street2:'',
            city:'',
            addressState:'',
            zip:'',
        });
        this.props.toggleShowAddShipTo();
    }

}
