import React from 'react';
import styles from './OrderHistoryCard.module.scss'
import { Dropdown, IDropdownOption, Label, Link, Stack, FontIcon, SpinButton, DatePicker, IconButton, TextField, Icon, IIconStyles, PrimaryButton } from '@fluentui/react';
import { IAddress, IOrder, IOrderLineItem  } from './interfaces';
import AddressCard from './AddressCard';
import ecoTheme from './ecoThemeVariables.module.scss'
import Select from 'react-select'
import { TSubmitStatus } from './OrderForm';
import moment from 'moment';

export interface IOrderHistoryCardState {

}

export interface IOrderHistoryCardProps {
    pastOrder:IOrder;
    reOrder(order:IOrder):void;
}


export default class OrderHistoryCard extends React.Component<IOrderHistoryCardProps, IOrderHistoryCardState> {

    public constructor(props: IOrderHistoryCardProps) {
        super(props);


        this.state = {

        };


    }
    public render(): React.ReactElement<IOrderHistoryCardProps> {

     
        return (
            <Stack className={styles.OrderHistoryCard} tokens={{childrenGap:15}}>
                <Stack horizontal tokens={{childrenGap:15}} verticalAlign='center'>
                    <Label>Date Submitted:</Label>
                    <div>{this.props.pastOrder && this.props.pastOrder.created ? new Date(this.props.pastOrder.created).toLocaleDateString() : undefined }</div>
                </Stack>
                <Stack horizontal tokens={{childrenGap:15}} horizontalAlign='space-between' wrap> 
                    <Stack horizontal tokens={{childrenGap:15}} wrap verticalAlign='start'>
                        

                        <Stack horizontal tokens={{childrenGap:15}} wrap>
                            <Stack>
                                <Label>Source</Label>
                                {this.props.pastOrder && this.props.pastOrder.source
                                        ?<AddressCard showEMLogo address={this.props.pastOrder.source} cardType='smallDisplay' />
                                        :<AddressCard cardType='blank' />
                                    }
                            </Stack>
                            <Stack>
                                <Label>Ship To</Label>
                                {this.props.pastOrder && this.props.pastOrder.shipTo
                                        ?<AddressCard address={this.props.pastOrder.shipTo} cardType='smallDisplay' />
                                        :<AddressCard cardType='blank' />
                                    }
                            </Stack>


                        </Stack>
                   </Stack>
                   
                    
                         
                   
                </Stack>

                
                <Stack horizontal tokens={{childrenGap:15}} verticalAlign='center' wrap>
                    <Stack>
                        <Label>Product</Label>
                        <div>{this.props.pastOrder && this.props.pastOrder.product && this.props.pastOrder.product.text? this.props.pastOrder.product.text : undefined}</div>
                    </Stack>

                    <Stack>
                        <Label>Loads</Label>
                        <div>{this.props.pastOrder && this.props.pastOrder.loadsQuantity ? this.props.pastOrder.loadsQuantity : undefined}</div>
                    </Stack>
                    
                    
                </Stack>
                <Stack horizontal tokens={{childrenGap:15}} wrap horizontalAlign='space-between'>
                    <Stack horizontal tokens={{childrenGap:15}} wrap={window.innerWidth <= 400 ? true : false} verticalAlign='center'>
                        <Stack>
                            <Label>Requested Pickup Date</Label>
                            <div>{this.props.pastOrder && this.props.pastOrder.requestedPickupDate ? moment(this.props.pastOrder.requestedPickupDate).format("MM/DD/YYYY") : undefined}</div>
                        </Stack>
                        <Stack>
                            <Label>Job Number or Purchase Order</Label>
                            <div>{this.props.pastOrder && this.props.pastOrder.jobOrPurchaseOrder ? this.props.pastOrder.jobOrPurchaseOrder : undefined}</div>
                        </Stack>
                        
                        
                    </Stack>
                    
                    <PrimaryButton text="Order Again" className={styles.ecoButton} onClick={()=>this.props.reOrder(this.props.pastOrder)} />
                    
                </Stack>
                
            </Stack>




        );
    }


}
